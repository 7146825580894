.description :nth-child(1) {
    min-width: 100px;
    width: auto;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
}

.notes :nth-child(1) {
    min-width: 100px;
    width: auto;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
}

