
.languagesWrapper .language {
    font-size: 1.1rem;
    transition: transform 0.3s ease;
}

.languagesWrapper .language:hover{
    transform: scale(1.07);
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    -ms-transform: scale(1.07);
    -o-transform: scale(1.07);
}

.languagesWrapper .selected {
    opacity: 1;
    font-weight: bold;
}

.languagesWrapper .unselected {
    opacity: 0.7;
}

