
.autocomplete :nth-child(2){
    cursor: pointer;
}

.autocomplete input{
    cursor: pointer;
}

div .MuiAutocomplete-hasPopupIcon .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 22px;
}

/* .autocomplete .MuiInputLabel-root.MuiInputLabel-formControl {
    transform: translate(8px, 9px) scale(1);
    -webkit-transform: translate(8px, 9px) scale(1);
    -moz-transform: translate(8px, 9px) scale(1);
    -ms-transform: translate(8px, 9px) scale(1);
    -o-transform: translate(8px, 9px) scale(1);
} */

/* .MuiAutocomplete-popper {
    width: 8rem !important;
} */

