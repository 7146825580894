

.containerPicker div {
    box-shadow:none;
}

@media (max-width: 850px) {

    .sideDefinedRanges {
        display: none;
    }
}
