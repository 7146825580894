
/* User profile */

.containerUser {
    display: flex;
    flex-direction: row;
}

.containerUserName {
    min-width: 50%;
}

@media (max-width: 1300px) {

    .containerUserName {
        min-width: 12rem;
    }

    .containerNameTel {
        padding-right: 1rem;
    }
}

@media (max-width: 1200px) {

    .containerUser {
        display: flex;
        flex-direction: column;
    }
}

/* Personal info */
.containerInfo {
    width: 57%;
}

@media (max-width: 1175px) {

    .containerInfo {
        width: 55%;
    }

}

.containerStreet {
    min-width: 33%;
}

.containerPostal {
    min-width: 33%;
}

.containerStatus {
    min-width: 33%;
}

.containerPersonal {
    display: flex;
}

.containerInfo {
    min-width: 57%;
}

@media (max-width: 1115px) {

    .containerPersonal {
        display: flex;
        flex-direction: column;
    }

    .containerUserName {
        min-width: 30%;
    }

    .containerInfo {
        min-width: 100%;
    }

    .containerStreet {
        min-width: 30%;
        width: auto;
    }

    .containerPostal {
        min-width: 30%;
    }

    .containerStatus {
        min-width: 30%;
    }
}

@media (max-width: 1000px) {
    .containerUserName {
        min-width: 12rem;
    }

    .containerStreet {
        min-width: 12rem;
    }    
}

/* /////////////////////////// */

/* Containers */

.containerContainer {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1100px) {

    .containerContainer {
        display: flex;
        flex-direction: column;
    }
}

/* Orders */

/* OrderDetail */

.containerOrderDetail {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1305px) {

    .containerOrderDetail {
        display: flex;
        flex-direction: column;
    }
}


.containerOrderInfo {
    flex-direction: row;
}

@media (max-width: 1127px) {

    .containerOrderInfo {
        flex-direction: column;
    }

    .orderCard {
        max-width: 33.8rem;
    }
}

/* Reservations */

.containerReservations {
    display: flex;
    flex-direction: row;
}

@media (max-width: 935px) {

    .containerReservations {
        display: flex;
        flex-direction: column;
    }
}

/* Orders */

/* .containerReservations {
    display: flex;
    flex-direction: row;
}

@media (max-width: 935px) {

    .containerReservations {
        display: flex;
        flex-direction: column;
    }
} */